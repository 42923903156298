"use client";
import Image from "next/image";
import { useRouter } from "next/navigation";
import React from "react";

export default function BrandedHomeButton() {
  const router = useRouter();

  const goHome = () => {
    router.push("/");
  };
  return (
    <Image
      src="/images/intelligent-office-logo-horizontal-inverted-rgb.svg"
      alt="Intelligent Office User Requests Portal"
      width={225}
      height={65}
      onClick={goHome}
      className="cursor-pointer"
    />
  );
}
