"use client";
import { useSession, signIn, signOut } from "next-auth/react";
import { Button } from "@/components/ui/button";

export default function AuthButton() {
  const { data: session } = useSession();

  if (session) {
    return (
      <Button variant="ghost" onClick={() => signOut()}>
        Sign out
      </Button>
    );
  }
  return (
    <>
      <Button className="w-full" onClick={() => signIn("azure-ad")}>
        Sign in
      </Button>
    </>
  );
}
